import React from 'react';
import BaseProps from '../common/interface/BaseProps';

export class Python extends React.Component<BaseProps> {
  render() {
    return (
      <svg
        className={this.props.className}
        viewBox="0 0 110.4211 109.8461"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            id="linearGradient11301"
          >
            <stop
              offset="0"
              style={{
                stopColor: '#ffe052',
                stopOpacity: 1,
              }}/>
            <stop
              id="stop11305"
              offset="1"
              style={{
                stopColor: '#ffc331',
                stopOpacity: 1,
              }}/>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            y2="168.1012"
            x2="147.77737"
            y1="111.92053"
            x1="89.136749"
            id="linearGradient11307"
            xlinkHref="#linearGradient11301"
          />
          <linearGradient
            id="linearGradient9515">
            <stop
              id="stop9517"
              offset="0"
              style={{
                stopColor: '#387eb8',
                stopOpacity: 1,
              }}/>
            <stop
              id="stop9519"
              offset="1"
              style={{
                stopColor: '#366994',
                stopOpacity: 1,
              }}/>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            y2="131.85291"
            x2="110.14919"
            y1="77.070274"
            x1="55.549179"
            id="linearGradient9521"
            xlinkHref="#linearGradient9515"
          />
        </defs>
        <g
          transform="translate(-473.36088,-251.72485)">
          <g
            transform="translate(428.42338,184.2561)">
            <path
              style={{
                opacity: 1,
                color: '#000000',
                fill: 'url(#linearGradient9521)',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
                strokeWidth: 1,
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                marker: 'none',
                markerStart: 'none',
                markerMid: 'none',
                markerEnd: 'none',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeDashoffset: 0,
                strokeOpacity: 1,
                visibility: 'visible',
                display: 'inline',
                overflow: 'visible',
              }}
              d="M 99.75,67.46875 C 71.718268,67.468752 73.46875,79.625 73.46875,79.625 L 73.5,92.21875 L 100.25,92.21875 L 100.25,96 L 62.875,96 C 62.875,96 44.9375,93.965724 44.9375,122.25 C 44.937498,150.53427 60.59375,149.53125 60.59375,149.53125 L 69.9375,149.53125 L 69.9375,136.40625 C 69.9375,136.40625 69.433848,120.75 85.34375,120.75 C 101.25365,120.75 111.875,120.75 111.875,120.75 C 111.875,120.75 126.78125,120.99096 126.78125,106.34375 C 126.78125,91.696544 126.78125,82.125 126.78125,82.125 C 126.78125,82.124998 129.04443,67.46875 99.75,67.46875 z M 85,75.9375 C 87.661429,75.937498 89.8125,78.088571 89.8125,80.75 C 89.812502,83.411429 87.661429,85.5625 85,85.5625 C 82.338571,85.562502 80.1875,83.411429 80.1875,80.75 C 80.187498,78.088571 82.338571,75.9375 85,75.9375 z "
            />
            <path
              d="M 100.5461,177.31485 C 128.57784,177.31485 126.82735,165.1586 126.82735,165.1586 L 126.7961,152.56485 L 100.0461,152.56485 L 100.0461,148.7836 L 137.4211,148.7836 C 137.4211,148.7836 155.3586,150.81787 155.3586,122.53359 C 155.35861,94.249323 139.70235,95.252343 139.70235,95.252343 L 130.3586,95.252343 L 130.3586,108.37734 C 130.3586,108.37734 130.86226,124.03359 114.95235,124.03359 C 99.042448,124.03359 88.421098,124.03359 88.421098,124.03359 C 88.421098,124.03359 73.514848,123.79263 73.514848,138.43985 C 73.514848,153.08705 73.514848,162.6586 73.514848,162.6586 C 73.514848,162.6586 71.251668,177.31485 100.5461,177.31485 z M 115.2961,168.8461 C 112.63467,168.8461 110.4836,166.69503 110.4836,164.0336 C 110.4836,161.37217 112.63467,159.2211 115.2961,159.2211 C 117.95753,159.2211 120.1086,161.37217 120.1086,164.0336 C 120.10861,166.69503 117.95753,168.8461 115.2961,168.8461 z "
              style={{
                opacity: 1,
                color: '#000000',
                fill: 'url(#linearGradient11307)',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
                strokeWidth: 1,
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                marker: 'none',
                markerStart: 'none',
                markerMid: 'none',
                markerEnd: 'none',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeDashoffset: 0,
                strokeOpacity: 1,
                visibility: 'visible',
                display: 'inline',
                overflow: 'visible',
              }}/>
          </g>
        </g>
      </svg>

    );
  }
}
